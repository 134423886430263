"use client";

import styles from "./sale-banner.module.scss";
import Link from "next/link";
import cx from "classnames";
import * as Images from "./assets";
import { IconChevronRight } from "@tabler/icons-react";
import Image from "next/image";
import { useEffect } from "react";
import { analytics } from "entity/analytics";
import { usePathname } from "next/navigation";
interface ISaleBannerProps {
  /* Планшетный баннер, заменяет собой большой. */
  /* Используется в основном для страницы заказа */
  tabletBanner?: boolean;
}
export const SaleBanner = (props: ISaleBannerProps) => {
  const {
    tabletBanner
  } = props;
  const pathname = usePathname();
  const bannerPath = pathname === "/men-home" ? "home" : pathname === "/women-home" ? "home" : pathname === "/my/wishlist" ? "favorites" : pathname.startsWith("/order") ? "order" : undefined;
  useEffect(() => {
    analytics.send(`love_day.banner.view-${bannerPath}`);
  }, []);
  return <>
      {!tabletBanner && <Link href="/sale" onClick={() => analytics.send(`love_day.banner.click-${bannerPath}`)} className={cx(styles.saleBanner, styles.desktop)}>
          <div className={styles.content}>
            <div className={styles.titleGroup}>
              <h2>
                <b>Скидки до 90%</b> на одежду и обувь
              </h2>
              <h3>для вас и ваших любимых</h3>
            </div>
            <span className={styles.designButton}>
              Товары со скидками <IconChevronRight />
            </span>
            <Image src={Images.Heart2} className={styles.heart2} alt="" />
            <Image src={Images.TShirt} className={styles.tshirt} alt="" />
            <Image src={Images.Hoodie} className={styles.hoodie} alt="" />
            <Image src={Images.Sneaker} className={styles.sneaker} alt="" />
            <Image src={Images.Heart1} className={styles.heart1} alt="" />
            <div className={styles.starBackground}>
              <Images.StarBackground />
            </div>
            <div className={styles.starImage}>
              <Images.StarImage />
            </div>
          </div>
        </Link>}
      {tabletBanner && <Link onClick={() => analytics.send(`love_day.banner.click-${bannerPath}`)} href="/sale" className={cx(styles.saleBanner, styles.tablet)}>
          <div className={styles.content}>
            <div className={styles.titleGroup}>
              <h2>
                <b>Скидки до 90%</b>
                <br />
                на одежду и обувь
              </h2>
              <h3>для вас и ваших любимых</h3>
            </div>
            <span className={styles.designButton}>
              Товары со скидками <IconChevronRight />
            </span>
            <Image src={Images.Heart2} className={styles.heart2} alt="" />
            <Image src={Images.TShirt} className={styles.tshirt} alt="" />
            <Image src={Images.Hoodie} className={styles.hoodie} alt="" />
            <Image src={Images.Sneaker} className={styles.sneaker} alt="" />
            <Image src={Images.Heart1} className={styles.heart1} alt="" />
            <div className={styles.starBackground}>
              <Images.StarBackgroundTablet />
            </div>
            <div className={styles.starImage}>
              <Images.StarImageTablet />
            </div>
          </div>
        </Link>}
      <Link onClick={() => analytics.send(`love_day.banner.click-${bannerPath}`)} href="/sale" className={cx(styles.saleBanner, styles.mobile)} data-sentry-element="Link" data-sentry-source-file="sale-banner.tsx">
        <div className={styles.content}>
          <div className={styles.titleGroup}>
            <h2>
              <b>Скидки до 90%</b>
              <br />
              на одежду и обувь
            </h2>
            <h3>для вас и ваших любимых</h3>
          </div>
          <span className={styles.designButton}>
            Товары со скидками <IconChevronRight data-sentry-element="IconChevronRight" data-sentry-source-file="sale-banner.tsx" />
          </span>
        </div>
        <Image src={Images.Heart2} className={styles.heart2} alt="" data-sentry-element="Image" data-sentry-source-file="sale-banner.tsx" />
        <Image src={Images.TShirt} className={styles.tshirt} alt="" data-sentry-element="Image" data-sentry-source-file="sale-banner.tsx" />
        <Image src={Images.Hoodie} className={styles.hoodie} alt="" data-sentry-element="Image" data-sentry-source-file="sale-banner.tsx" />
        <Image src={Images.Sneaker} className={styles.sneaker} alt="" data-sentry-element="Image" data-sentry-source-file="sale-banner.tsx" />
        <Image src={Images.Heart1} className={styles.heart1} alt="" data-sentry-element="Image" data-sentry-source-file="sale-banner.tsx" />
        <div className={styles.starBackground}>
          <Images.StarBackgroundMobile data-sentry-element="unknown" data-sentry-source-file="sale-banner.tsx" />
        </div>
        <div className={styles.starImage}>
          <Images.StarImageMobile data-sentry-element="unknown" data-sentry-source-file="sale-banner.tsx" />
        </div>
      </Link>
    </>;
};